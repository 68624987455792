/* 
* @Author: liuzhixiang  
* @Date: 2021-08-07 09:34:51  
 * @Last Modified by: liuzhixiangg
 * @Last Modified time: 2021-09-01 10:25:077
*/
<template>
  <div class="details">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
      <div class="cont">
        <div class="title">{{ details.Title }}</div>
        <div class="time">
          <span>{{ details.CreateTime | dataYMD }}</span>
          <span>{{ details.ReadCount }}人看过</span>
        </div>
        <div class="content" v-html="details.ContentStr"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonQueryNewsDetail } from "@/api/home";
import noData from "@/components/noData";
import BreadCrumb from "@/components/breadcrumb";
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      details: {},
    };
  },
  components: {
    noData, BreadCrumb
  },
  created () {
    this.route = this.$route.query;
  },
  mounted () {
    this.init();
  },
  methods: {
    //
    async init () {
      let parm = "?id=" + this.route.id;
      const res = await commonQueryNewsDetail(parm);
      if (res.success === true) {
        this.details = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  .cont {
    background-color: #fff;
    padding: 20px;
    .title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
    .time {
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      // margin: 10px 0 0;
      padding: 20px 0;
      border-bottom: 1px solid #e7e7e7;
      text-align: center;
      span + span {
        margin-left: 60px;
      }
    }
    .content {
      line-height: 1.8;
      padding: 15px 0;
      font-size: 13px;
      color: #666666;
      /deep/img,
      /deep/video {
        max-width: 100%;
      }
    }
  }
}
</style>